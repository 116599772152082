
.navigation-wrapper {
    position: relative;
  }
  
  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #1C407F;
    cursor: pointer;
  }
  
  .arrow--left {
    left: 5px;
  }
  
  .arrow--right {
    left: auto;
    right: 5px;
  }
  
  /* .arrow--disabled {
    fill: #a0b7d3
  } */

  .w100{
    width: 100%;
  }

  .dflex{
    display: flex;
  }

  .verticalStaticPageTabs .MuiTabs-indicator{
    background: transparent;
  }

  .verticalStaticPageTabs .MuiTab-root{
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-left: 4px solid #0f3f83;
    border-bottom: 4px solid transparent;
    font-weight: 700;
    color:#0f3f83 !important;
    background-color: #fff;
    align-items: flex-start;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    text-transform: none;
    max-width: 100%;
    transition: all 0.25s ease-in 0s;
    -webkit-transition: all 0.25s ease-in 0s;;
  }

  .verticalStaticPageTabs .MuiTab-root:hover, .verticalStaticPageTabs .MuiTab-root.Mui-selected{
    border-left-color: #fbaa1a;
    border-bottom-color: #fbaa1a;
    color:#fbaa1a !important;
  }

  .customer_service_bx a{
    text-decoration: none;
    color: #0f3f83;
  }

  .coockeLink {
    text-decoration: none;
    color:#fbaa1a !important;
  }