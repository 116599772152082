@font-face {
    font-family: 'helvetica';
    src: url('./helvetica-webfont.eot');
    src: url('./helvetica-webfont.eot?#iefix') format('embedded-opentype'),
         url('./helvetica-webfont.woff2') format('woff2'),
         url('./helvetica-webfont.woff') format('woff'),
         url('./helvetica-webfont.ttf') format('truetype'),
         url('./helvetica-webfont.svg#helveticaregular') format('svg');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'helvetica';
    src: url('./helvetica-bold-webfont.eot');
    src: url('./helvetica-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./helvetica-bold-webfont.woff2') format('woff2'),
         url('./helvetica-bold-webfont.woff') format('woff'),
         url('./helvetica-bold-webfont.ttf') format('truetype'),
         url('./helvetica-bold-webfont.svg#helveticabold') format('svg');
    font-weight: 700;
    font-style: normal;

}